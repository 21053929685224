<template>
  <div class="task-area mb-16" v-if="data">
    <div class="pb-8" style="border-bottom: 1px solid #d8dae5">
      <div class="d-flex align-items-center space-between p-16">
        <div class="d-flex align-items-center">
          <svg-bars class="grp-bars" wp-cls="mr-8" />
          <span class="ml-8 fb-16">{{ name }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="grp-error" v-if="isEdit && !checkValid"
            >항목을 모두 입력헤주세요</span
          >
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            wp-cls="mr-8"
            @click="onRemoveGrp"
            v-if="!allDisabled"
            ><span class="f-red">삭제</span></button-common
          >
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            wp-cls="mr-8"
            v-if="isEdit && !allDisabled"
            @click="onCancel"
            >취소</button-common
          >
          <button-common
            :size="SIZE_T"
            :clr="CLR_M"
            :disabled="!checkValid"
            wp-cls="mr-16"
            v-if="isEdit && !allDisabled"
            @click="onSave"
            >저장</button-common
          >
          <button-common
            :size="SIZE_T"
            :clr="CLR_W"
            wp-cls="mr-16"
            v-if="!isEdit && !allDisabled"
            @click="isEdit = true"
            >수정</button-common
          >
          <div class="toggle category_toggle">
            <svg-arrow-up v-show="isShow" @click="isShow = false" />
            <svg-arrow-dn v-show="!isShow" @click="isShow = true" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isShow">
      <!-- 코스 선택 후 -->
      <div>
        <div class="sale-course-data">
          <span class="sale-course-data-title">이름</span>
          <input-text
            :value="data.name"
            :useCounter="true"
            :maxLength="45"
            style="width: 500px"
            @input="onInputData($event, 'name')"
            v-if="isEdit"
          />
          <span class="sale-course-data-content" v-else>{{ data.name }}</span>
        </div>
        <div class="sale-course-data" style="border-bottom: 1px solid #d8dae5">
          <span class="sale-course-data-title">유형</span>
          <input-radio
            :modelValue="data.type"
            val-nm="M"
            wpCls="mr-32"
            @input="onInputData($event, 'type')"
            v-if="isEdit"
            >월</input-radio
          >
          <input-radio
            :modelValue="data.type"
            val-nm="N"
            wpCls="mr-32"
            @input="onInputData($event, 'type')"
            v-if="isEdit"
            >회</input-radio
          >
          <span class="sale-course-data-content" v-else>{{ data.type }}</span>
          <span
            class="grp-check"
            style="margin-left: 250px"
            v-if="isEdit && data.type === 'M'"
            >월 선택 시 할인전 금액에 월 이용료를 입력해주세요.</span
          >
          <span
            class="grp-check"
            style="margin-left: 250px"
            v-if="isEdit && data.type === 'N'"
            >회 선택 시 할인전 금액에 회차별 이용료를 입력해주세요.</span
          >
        </div>
        <div class="sale-course-data">
          <span class="sale-course-data-title">할인전 금액</span>
          <input-text
            :value="data.price"
            :unit="'원'"
            @input="onInputData($event, 'price')"
            v-if="isEdit"
          />
          <span class="sale-course-data-content" v-if="!isEdit && data.price"
            >{{ formatPrice(data.price) }}원</span
          >
          <span
            class="grp-check"
            style="margin-left: 183px"
            v-if="isEdit && !data.price"
            >할인전 금액을 입력해주세요.</span
          >
          <span
            class="grp-check"
            style="margin-left: 183px"
            v-if="isEdit && data.price"
            >{{ formatPrice(data.price) }}원</span
          >
        </div>
        <div class="sale-course-data" v-if="data.type === 'N'">
          <span class="sale-course-data-title">월 횟수 설정</span>
          <select-box
            :value="data.minCnt"
            :opt-nm="SELT_PAYMENT_MINMAX"
            :size="SIZE_A"
            @input="onInputData($event, 'minCnt')"
            v-if="isEdit"
          />
          <span class="ml-16 mr-16" v-if="isEdit">~</span>
          <select-box
            :value="data.maxCnt"
            :opt-nm="SELT_PAYMENT_MINMAX"
            :size="SIZE_A"
            @input="onInputData($event, 'maxCnt')"
            v-if="isEdit"
          />
          <span class="sale-course-data-content" v-else
            >{{ data.minCnt }} ~ {{ data.maxCnt }}</span
          >
        </div>
        <div class="sale-course-data" style="border-top: 1px solid #d8dae5">
          <span class="sale-course-data-title">할인율 설정</span>
          <button-common
            :size="SIZE_T"
            clr="w"
            @click="onAddDiscount"
            v-if="isEdit"
          >
            + 추가
          </button-common>
        </div>
        <template v-if="isEdit">
          <div
            class="flex-space-between flex-align-center"
            style="border-bottom: 1px solid #d8dae5"
            v-for="(discount, index) in discountArr"
            :key="index"
          >
            <div class="sale-course-data">
              <span class="sale-course-data-title">{{ index + 1 }}.</span>
              <select-box
                class="mr-8"
                v-model="discount.month"
                :opt-nm="SELT_PAYMENT_MONTH"
                :size="SIZE_A"
                @input="onInputData($event, 'itemList')"
              />
              <span class="mr-16">개월</span>
              <input-text
                class="mr-8"
                v-model="discount.discountPercent"
                @input="onInputData($event, 'itemList')"
              />
              <span>%</span>
              <span
                class="grp-check"
                style="margin-left: 53px"
                v-if="isEdit && !discount.month"
                >개월을 선택해주세요.</span
              >
              <span
                class="grp-check"
                style="margin-left: 53px"
                v-else-if="isEdit && !discount.discountPercent"
                >할인율을 입력해주세요.</span
              >
              <span
                class="grp-check"
                style="margin-left: 53px"
                v-else-if="
                  isEdit &&
                  discount.month &&
                  discount.discountPercent &&
                  calDiscountPrice(discount.discountPercent) >= 1000
                "
                >{{
                  formatPrice(calDiscountPrice(discount.discountPercent))
                }}원</span
              >
              <span
                class="grp-error"
                style="margin-left: 53px"
                v-else-if="
                  isEdit &&
                  discount.month &&
                  discount.discountPercent &&
                  calDiscountPrice(discount.discountPercent) < 1000
                "
                >할인금액이 1,000원 미만일 경우 등록이 되지 않습니다.</span
              >
            </div>
            <img
              class="icon-marketing-delete"
              src="@/assets/image/marketing_delete.png"
              @click="deleteDiscount(index)"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="sale-course-data"
            style="border-bottom: 1px solid #d8dae5"
            v-for="(discount, index) in discountArr"
            :key="index"
          >
            <span class="sale-course-data-title">{{ index + 1 }}.</span>
            <span class="sale-course-data-content"
              >{{ discount.month }}개월 {{ discount.discountPercent }}%</span
            >
            <span
              class="grp-check"
              style="margin-left: 53px"
              v-if="discount.month && discount.discountPercent"
              >{{
                formatPrice(calDiscountPrice(discount.discountPercent))
              }}원</span
            >
          </div>
        </template>
        <div class="sale-course-data">
          <span class="sale-course-data-title">해지위약금</span>
          <input-check
            :value="data.penalty"
            @input="onInputData($event, 'penalty')"
            v-if="isEdit"
          >
            없음
          </input-check>
          <span class="sale-course-data-content" v-else>{{
            data.penalty === "true" ? "없음" : "있음"
          }}</span>
          <span class="grp-check" style="margin-left: 343px" v-if="isEdit"
            >해지위약금이 없을시 체크해주세요.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import IconButton from "@/components/ui/icon/IconButton";
import TaskModule from "@/components/ui/module/TaskModule";

export default {
  name: "PaymentGrp",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {
    TaskModule,
    IconButton,
    draggable,
  },

  /*** props ***/
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    allDisabled: {
      type: Boolean,
      default: false,
    },
  },

  /*** data ***/
  data() {
    return {
      title: "",
      isShow: true,
      isEdit: false,
      data: null,
      discountArr: [],
      content: {
        id: "",
        title: "",
        type: "",
        feedType: "",
        taskEsntlId: null,
        numberOrder: -1,
        dayOrder: -1,
      },
    };
  },

  /*** created ***/
  created() {},

  mounted() {
    this.data = this.item ? JSON.parse(JSON.stringify(this.item)) : null;
    this.discountArr = this.data.itemList ? this.data.itemList : [];
  },

  /*** computed ***/
  computed: {
    checkValid() {
      let result = false;
      if (this.data) {
        result =
          this.data.name &&
          this.data.type &&
          this.data.price &&
          this.discountArr.length > 0 &&
          this.discountArr.filter(
            (item) => !item.discountPercent || !item.month
          ).length === 0 &&
          this.discountArr.filter(
            (item) => this.calDiscountPrice(item.discountPercent) === 0
          ).length === 0;
        if (this.data.type === "N" && result) {
          result = this.data.maxCnt && this.data.minCnt;
        }
      }
      console.log(result);
      return result;
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    onAddDiscount() {
      const discount = {
        month: "",
        discountPercent: "",
      };
      this.discountArr.push(discount);
    },

    /**@ clone group **/
    onCloneTask(e) {
      this.$emit("clone", this.data.id);
    },

    /**@ remove group **/
    onRemoveGrp(e) {
      this.$emit("remove", this.data.id);
    },

    onCancel() {
      this.isEdit = false;
      this.data = this.item ? JSON.parse(JSON.stringify(this.item)) : null;
      this.discountArr = this.item.itemList ? this.item.itemList : [];
    },

    onSave() {
      this.isEdit = false;
      this.data.itemList = this.discountArr;
      this.$emit("input", this.data);
    },

    onInputData(value, key) {
      this.data[key] = key === "itemList" ? this.discountArr : value;
    },

    deleteDiscount(index) {
      this.discountArr = this.discountArr.filter(
        (item, itemIndex) => itemIndex !== index
      );
    },

    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    calDiscountPrice(discountPercent) {
      const price = parseFloat(this.data.price);
      const percent = parseFloat(discountPercent);
      if (!price) {
        return "-";
      }
      if (!percent) {
        return price;
      }
      const result = (price - (price * percent) / 100).toFixed();
      if (result < 1000) {
        return 0;
      } else {
        return Math.floor(result / 1000) * 1000;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grp-bars {
  cursor: grab;
}

.mod-component.task-title:first-child {
  border-top: 1px solid var(--gray20);
}

.mod-component.task-title:last-child {
  border-bottom: none;
}

.sale-course-data {
  display: flex;
  align-items: center;
  padding: 16px 62px;
}

.sale-course-data-title {
  width: 130px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 58px;
}

.sale-course-data-content {
  font-size: 16px;
  font-weight: 400;
  color: #696c80;
}

.not-select-course {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.grp-error {
  font-size: 12px;
  font-weight: 400;
  color: #f1431d;
  margin-right: 32px;
}
.grp-check {
  color: #2ec8b5;
  font-size: 12px;
  font-weight: 700;
}
.icon-marketing-delete {
  width: 20px;
  height: 20px;
  margin-right: 40px;
  cursor: pointer;
}
</style>
