<template>
  <div class="module-wrapper task-form d-flex w-100">
    <h4 class="module-title fm-14 d-flex align-items-center">
      <svg-bars class="mod-bars" wp-cls="mr-8"/>
      {{ `Task${idx}` }}
    </h4>
    <div class="module-wrap d-flex w-100 p-16">
      <div class="group-item">
        <!-- 테스크 없을 경우 -->
        <div class="d-flex" v-if="!data.title">
          <div class="task-div div-null mr-16">테스크를 불러오세요.</div>
          <button-common :size="SIZE_T" :clr="CLR_W" @click="onLoadTask">불러오기</button-common>
        </div>
        <!-- 테스크 있을 경우 -->
        <div class="d-flex" v-else>
          <div class="task-div div-not-null mr-16">
            <span class="span-type-study" v-if="data.type === 'STUDY'">학습형</span>
            <span class="span-type-exe" v-else>실행형</span>
            <span class="span-title">{{ data.title }}</span>
            <span class="span-feedType" v-if="data.feedType === 'COM'">코멘트</span>
            <span class="span-feedType" v-if="data.feedType === 'QnA'">Q&A</span>
          </div>
          <button-common :size="SIZE_T" :clr="CLR_W" @click="onLoadTask">수정</button-common>
        </div>
      </div>
      <div class="group-etc d-flex column space-between">
        <svg-remove-red @click="$emit('remove', id)"/>
      </div>
    </div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'


export default {
  name: 'TaskModule',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    id: {
      type: String,
      default: '',
    },
    idx: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {}
    },
  },

  /*** data ***/
  data() {
    return {
      task: {
        title: '',
        type: ''
      },
    }
  },

  /*** created ***/
  created() {

  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    handleUpload(e) {
      this.$emit('inputLink', e)
    },
    onLoadTask() {
      this.$emit('loadTask')
    },
  },
}
</script>

<style scoped lang="scss">
.group-item {
  overflow: hidden;
}
.mod-bars {
  cursor: grab;
}
.task-div {
  width: 640px;
  height: 36px;
  border: 1px solid #D8DAE5;
  background: white;
  font-size: 14px;
}
.div-null {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-not-null {
  display: flex;
  align-items: center;
  padding: 8px;
}
.span-type-study {
  font-size: 14px;
  color: #2CCDC3;
  margin-right: 60px;
  font-weight: 400;
}
.span-type-exe {
  font-size: 14px;
  color: #F1431D;
  margin-right: 60px;
  font-weight: 400;
}
.span-title {
  font-size: 14px;
  margin-right: 36px;
  font-weight: 500;
  width: 360px;
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.span-feedType {
  font-size: 14px;
  color: #828699;
  font-weight: 400;
}
</style>
