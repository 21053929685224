<template>
  <div :class="cls" @click="handleChangeCommon">
    <img :src="src" />
    <div><slot /></div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'IconButton',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {  },

  /*** props ***/
  props: {

  },

  /*** data ***/
  data() {
    return {
      iconType: {
        text: '/image/icon/30px/text.png',
        image: '/image/icon/30px/plus-image.png',
        popup: '/image/icon/30px/text.png',
        video: '/image/icon/30px/video.png',
        youtube: '/image/icon/30px/youtube.png',
        file: '/image/icon/30px/file.png',
        link: '/image/icon/30px/link.png',
        mission: '/image/icon/30px/mission.png',
      }
    }
  },

  /*** created ***/
  created() {

  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'task-content-btn '
      cls += (this.wpCls || '')
      return cls
    },
    src() {
      return this.iconType[this.srcNm]
    }
  },

  /*** watch ***/
  watch: {

  },

  /*** methods ***/
  methods: {

  },
}
</script>

<style scoped lang="scss">
.task-content-btn {
  cursor: pointer;
}
</style>
